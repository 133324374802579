<!------------------------------------------TITLE----------------------------------------------->
<div class="title-box uk-border-rounded p-1 d-none d-sm-block">
  <div class="head-card p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      Our Products
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/clients"> Clients</span>
      </h5>
    </div>
  </div>
</div>
<!--------------------------------------------------TITLE END-------------------------------------------->


<div class="container mt-5 mb-4">
  <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
    Our Products
  </h4>

  <div class="row pb-3">
    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/temple.jpg" alt="image1" class="profile-icon" />
        <div class="profile-name">Temple Software </div>
        <div class="profile-position">Software</div>
        <a (click)="moveTemplesw()" class="button">Know More</a>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/test-retail.jpg" alt="image1" class="profile-icon" />
        <div class="profile-name">Retail Management </div>
        <div class="profile-position">Software</div>
        <a (click)="moveRetail()" class="button">Know More</a>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/test-hospital.jpg" alt="image2" class="profile-icon" />
        <div class="profile-name"> Hospital Management</div>
        <div class="profile-position">Software</div>
        <a (click)="moveAntibiotic()" class="button">Know More</a>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/test-school.jpg" alt="image3" class="profile-icon" />
        <div class="profile-name"> Student Management</div>
        <div class="profile-position">Software</div>
        <a (click)="moveSchool()" class="button">Know More</a>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/test-library.jpg" alt="image2" class="profile-icon" />
        <div class="profile-name"> Library Management</div>
        <div class="profile-position">Software</div>
        <a (click)="moveLodge()" class="button">Know More</a>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/test-enng.jpg" alt="image1" class="profile-icon" />
        <div class="profile-name">Engineering</div>
        <div class="profile-position">Software</div>
        <a (click)="moveEngineering()" class="button">Know More</a>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/test-ticket.jpg" alt="image2" class="profile-icon" />
        <div class="profile-name">Ticket Booking</div>
        <div class="profile-position">Software</div>
        <a (click)="moveTicket()" class="button">Know More</a>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/test-inv.jpg" alt="image3" class="profile-icon" />
        <div class="profile-name">Inventory Management </div>
        <div class="profile-position">Software</div>
        <a (click)="moveInventory()" class="button">Know More</a>
      </div>
    </div>

    <div class="col-md-3 col-sm-6 col-12">
      <div class="profile-card">
        <img src="./../../assets/img/products/test-pharma.jpg" alt="image2" class="profile-icon" />
        <div class="profile-name">Pharmacy Management </div>
        <div class="profile-position">Software</div>
        <a (click)="moveEllora()" class="button">Know More</a>
      </div>
    </div>
  </div>
</div>