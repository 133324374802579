<div class=" d-none d-sm-block">
	<!------------------------------------------TITLE----------------------------------------------->
	<div class="title-box uk-border-rounded p-1 d-none d-sm-block">
		<div class="head-card p-1 inner-title-box">
		  <h1 class="title-text text-center font-weight-bold">
			Verticals
		  </h1>
		  <div class="text-right">
			<h5 class="text-light">
			  <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
			  <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
			  <span class="sub-menu" routerLink="/ourproducts"> Our Products</span>
			</h5>
		  </div>
		</div>
	   </div>
	  <!--------------------------------------------------TITLE END-------------------------------------------->
	<!------------------------------------------------------------CAROSUEL----------------------------------------------------->
	<div class="container p-4">
		<h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
			verticals
		  </h4>
		<div class="row animatable fadeInUp">
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-medkit service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Hospital Management System
						</h6>
						<p class="text-muted">
							Health Care.
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-dashboard service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Temple Management System
						</h6>
						<p class="text-muted">
							Religious. 
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-hotel service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Hotel Management System
						</h6>
						<p class="text-muted">
							Restaurants. 
						</p><br />
					</div>
				</div>
			</div> 
		</div><br />
		<div class="row  animatable fadeInUp">
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-university service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							School Management System
						</h6>
						<p class="text-muted">
							Education. 
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-university service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							college Management System
						</h6>
						<p class="text-muted">
							Education. 
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-users service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Value Added Services
						</h6>
						<p class="text-muted">
							Bio metric Solutions.
							Bio metric Solutions.
						</p><br />
					</div>
				</div>
			</div> 
		</div><br />
		<div class="row  animatable fadeInUp">
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center icon-border">
						<span class="fa fa-shopping-cart service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Retail
						</h6>
						<p class="text-muted">
							Chits Management.
							Jewellery Management.
							Bakery Management 
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-shopping-cart service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Retail
						</h6>
						<p class="text-muted">
							Pawn Broking.
							Whole Sale Management.
							Textile Management.
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-shopping-cart service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Retail
						</h6>
						<p class="text-muted">
							Furniture Management.
							Super market Management. 
						</p><br />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>




<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<div class=" d-block d-sm-none">
	<!------------------------------------------------------------CAROSUEL----------------------------------------------------->
	<div class="container p-4">
		<h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
			verticals
		  </h4>
		<div class="row">
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-medkit service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Hospital Management System
						</h6>
						<p class="text-muted">
							Health Care.
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-dashboard service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Temple Management System
						</h6>
						<p class="text-muted">
							Religious. 
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-hotel service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Hotel Management System
						</h6>
						<p class="text-muted">
							Restaurants. 
						</p><br />
					</div>
				</div>
			</div> 
		</div><br />
		<div class="row">
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-university service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							School Management System
						</h6>
						<p class="text-muted">
							Education. 
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-university service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							college Management System
						</h6>
						<p class="text-muted">
							Education. 
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-users service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Value Added Services
						</h6>
						<p class="text-muted">
							Bio metric Solutions.
							Bio metric Solutions.
						</p><br />
					</div>
				</div>
			</div> 
		</div><br />
		<div class="row">
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center icon-border">
						<span class="fa fa-shopping-cart service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
						Pawn
						</h6>
						<p class="text-muted">
							Chits Management.
							Jewellery Management.
							Bakery Management 
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-shopping-cart service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Retail
						</h6>
						<p class="text-muted">
							<!-- Pawn Broking. -->
							Whole Sale Management.
							Textile Management.
						</p><br />
					</div>
				</div>
			</div> 
			<div class="col-md-4 col-sm-12 col-xs-12">
				<div class="card service-card border-0 p-4">
					<div class="text-center">
						<span class="fa fa-shopping-cart service-icon" aria-hidden="true"></span>
						
						<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
							Retail
						</h6>
						<p class="text-muted">
							Furniture Management.
							Super market Management. 
						</p><br />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
