 <div class=" d-none d-sm-block">
   <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1 d-none d-sm-block" id="titlebox" #titlebox>
  <div class="head-card p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      <p style="color: #ffffff;">about us</p>
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">About Us</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/custom-software-development"> Service</span>
      </h5>
    </div>
  </div>
 </div>
<!--------------------------------------------------TITLE END-------------------------------------------->

<!-------------------------------------------ABOUT US----------------------------------------------------->
<div class="bg-light">
  <div class="container-fluid">
      <div class="container row mt-3">
        <div class="border border-bottom-0 border-left-0 border-top-0 p-2 d-none d-sm-block">
          <span class="fa fa-users head-icon"></span>
        </div>
        <h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
          Little About Us
        </h2>
        <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
        little about us
        </h4>
    </div>
    <br />
      <div class="row mt-3">
          <div class="col-md-8 col-sm-12 col-xs-12  w3-animate-left">
            <div class="main-timeline">
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-user-o"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Web Development</h3> -->
                    <p class="description">
                        Annaa Silicon Technology is a complete IT Services company with major focus on Retail,
                        religion and Health Verticals. The Company is head quartered in Kumbakonam, India 
                        and recently expanded its operations in Chennai, India. The Company is mentored by
                        Domain Experts and entrepreneurs from Phoenix, USA and UK.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Web Desiging</h3> -->
                    <p class="description">
                        The Company has been delivering end-to-end software development,
                        quality assurance and Annual maintenance support solutions to clients ranging 
                        from Fortune 1000 companies to startups in all stages of funding since 2003.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-rocket"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Java Script</h3> -->
                    <p class="description">
                        Our mission is to provide high value IT solutions and services to our customers 
                        with distinctive competence and integrity by working together for the shared long 
                        term objectives.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-cog"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Web Desiging</h3> -->
                    <p class="description">
                        Annaa Silicon Technology provides premier full service Web, Mobile App development
                        functionalities. For our clients software is a critical success factor. 
                        To every project, we bring a combination of domain expertise, exceptional engineering 
                        talent, established best practices. Our commitment to protect client's data using 
                        industry best security governance comes with over a decade of impeccable reputation.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-diamond"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Java Script</h3> -->
                    <p class="description">
                        We have proudly deployed turnkey to hundreds of our clients in and around Tamil Nadu.
                        Our Diverse customers vary from large corporations to dynamic startups and
                        entrepreneurs.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-globe"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Web Desiging</h3> -->
                    <p class="description">
                        At Annaa Silicon, we provide top notch solutions, as we make our best efforts to
                        fully understand the precise needs of our clients. We provide the same commitment 
                        and effort to each of our solution regardless of size, hence we have a wide range of
                        cliental base with the mixture of small scale industries, corporate and government
                        bodies.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-12 w3-animate-right">
              <div class="card service-card border-0 p-4">
                  <div class="text-center">
                      <span class="fa fa-eye fa-2x service-icon" aria-hidden="true"></span>
                      
                      <h6 class="text-center text-uppercase font-weight-bolder pb-1">
                          VISION
                      </h6>
                      <p class="text-left">
                          To be a Customer focused organization providing World Class Software and 
                              Infrastructure services
                      </p>
                  </div>
              </div>
              
              <div class="card service-card border-0 p-4">
                  <div class="text-center">
                      <span class="fa fa-rocket fa-2x service-icon" aria-hidden="true"></span>
                      <h6 class="text text-center text-uppercase font-weight-bolder pb-1">
                          MISSION
                      </h6>
                      <p class="text-left">
                          Our value to your business.customers Ontime Cost Effective Best In Class Services<br>
                          innovate and accelerate growth in product and time to market<br>
                          nurture talent
                          strive to enhance Stakeholder value 
                      </p>
                  </div>
              </div>
              
              <div class="card service-card border-0 p-4">
                  <div class="text-center">
                      <span class="fa fa-wrench fa-2x service-icon" aria-hidden="true"></span>
                      <h6 class="text-center text-uppercase font-weight-bolder pb-1">
                          TALENT POOL
                      </h6>
                      <p class="text-left">
                          Technical Leads,<br>
                      Project Managers,<br>
                      Business Domain Experts, <br>
                      Software Engineers,<br>
                      Integration and <br>
                      Test Development,<br>
                      UI Designers,<br>
                      Technical Writers,<br>
                      Support Personnel. 
                      </p>
                  </div>
              </div>
          </div>
        </div>
  <!----------------------------------------------ABOUT US END----------------------------------------------->

  <!-----------------------------------------UNIQUE ABOUT US------------------------------------------------->
      <div class="row mt-3">
          <div class="col-md-6 col-sm-12 col-xs-12 color" style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
              <div class="p-3">
                  <h1 class="text-dark-blue d-none d-sm-block">
                      <u>UNIQUE ABOUT US</u>
                  </h1>
                  <h5 class="text-dark-blue  font-weight-bold text-center d-block d-sm-none">
                    <u>UNIQUE ABOUT US</u>
                  </h5>
                  <p class="text-dark-blue "> 
                      We Offer<b class="b">Cost Effective Solutions </b>to the customers based on their current need and future demand.
                  </p>
                  <p class="text-dark-blue "> 
                      We take our Commitment very seriously – <b class="b">On Time Delivery</b>.
                  </p>
                  <p class="text-dark-blue ">
                      Team of <b class="b">Skilled experts</b> in each domain are available for Consultation.
                  </p>
                  <p class="text-dark-blue ">
                      <b class="b">24 x 7</b> x 365 Support.
                  </p>
                  <p class="text-dark-blue ">
                      Dedicated <b class="b"> Domain Experts</b> assigned to each vertical to have dedicated focus.
                  </p>
                  <p class="text-dark-blue ">
                      On going <b class="b"> Training </b>to both employees and customers.
                  </p>
                  <p class="text-dark-blue ">
                      which enables our products <b class="b">Accelerated Time to Market</b>.
                  </p>
                  <p class="text-dark-blue ">
                      Offer Industry <b class="b">Best practices and suggestions </b> to drive Revenue growth.
                  </p>
                  <p class="text-dark-blue ">
                      Offer Internship to college grads with a strong Will to Win.
                  </p>
              </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12" style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
              <img src="./../../assets/img/about-us/rb_18771.png" alt="img" 
              style="width:100%;height:100%;padding-left: 0px; padding-right: 0px; margin-left: 0px;margin-bottom: 0px;
              margin-right: 0px;">
          </div>
      </div>
  </div>
</div>
 <!---------------------------------------UNIQUE ABOUT US END------------------------------------------------->
 </div>

<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<div class=" d-block d-sm-none">
   <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1 d-none d-sm-block" id="titlebox" #titlebox>
  <div class="head-card p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      about us
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">About Us</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/custom-software-development"> Service</span>
      </h5>
    </div>
  </div>
 </div>
<!--------------------------------------------------TITLE END-------------------------------------------->

<!-------------------------------------------ABOUT US----------------------------------------------------->
<div class="bg-light">
  <div class="container-fluid">
      <div class="container row mt-3">
        <div class="border border-bottom-0 border-left-0 border-top-0 p-2 d-none d-sm-block">
          <span class="fa fa-users head-icon"></span>
        </div>
        <h4 class="font-weight-bolder align-middle text-center text-uppercase">
        little about us
        </h4>
    </div>
    <br />
      <div class="row mt-3">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <div class="main-timeline">
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-user-o"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Web Development</h3> -->
                    <p class="description" style="text-align: center;">
                        Annaa Silicon Technology is a complete IT Services company with major focus on Retail,
                        religion and Health Verticals. The Company is head quartered in Kumbakonam, India 
                        and recently expanded its operations in Chennai, India. The Company is mentored by
                        Domain Experts and entrepreneurs from Phoenix, USA and UK.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-cubes"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Web Desiging</h3> -->
                    <p class="description" style="text-align: center;">
                        The Company has been delivering end-to-end software development,
                        quality assurance and Annual maintenance support solutions to clients ranging 
                        from Fortune 1000 companies to startups in all stages of funding since 2003.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-rocket"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Java Script</h3> -->
                    <p class="description" style="text-align: center;">
                        Our mission is to provide high value IT solutions and services to our customers 
                        with distinctive competence and integrity by working together for the shared long 
                        term objectives.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-cog"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Web Desiging</h3> -->
                    <p class="description" style="text-align: center;">
                        Annaa Silicon Technology provides premier full service Web, Mobile App development
                        functionalities. For our clients software is a critical success factor. 
                        To every project, we bring a combination of domain expertise, exceptional engineering 
                        talent, established best practices. Our commitment to protect client's data using 
                        industry best security governance comes with over a decade of impeccable reputation.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-diamond"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Java Script</h3> -->
                    <p class="description" style="text-align: center;">
                        We have proudly deployed turnkey to hundreds of our clients in and around Tamil Nadu.
                        Our Diverse customers vary from large corporations to dynamic startups and
                        entrepreneurs.
                    </p>
                  </div>
                </a>
              </div>
              <div class="timeline">
                <a href="#" class="timeline-content">
                  <div class="timeline-icon">
                    <i class="fa fa-globe"></i>
                  </div>
                  <div class="inner-content">
                    <!-- <h3 class="title">Web Desiging</h3> -->
                    <p class="description" style="text-align: center;">
                        At Annaa Silicon, we provide top notch solutions, as we make our best efforts to
                        fully understand the precise needs of our clients. We provide the same commitment 
                        and effort to each of our solution regardless of size, hence we have a wide range of
                        cliental base with the mixture of small scale industries, corporate and government
                        bodies.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-12 w3-animate-right">
              <div class="card service-card border-0 p-4">
                  <div class="text-center">
                      <span class="fa fa-eye fa-2x service-icon" aria-hidden="true"></span>
                      
                      <h6 class="text-center text-uppercase font-weight-bolder pb-1">
                          VISION
                      </h6>
                      <p class="text-center">
                          To be a Customer focused organization providing World Class Software and 
                              Infrastructure services
                      </p>
                  </div>
              </div>
              
              <div class="card service-card border-0 p-4">
                  <div class="text-center">
                      <span class="fa fa-rocket fa-2x service-icon" aria-hidden="true"></span>
                      <h6 class="text text-center text-uppercase font-weight-bolder pb-1">
                          MISSION
                      </h6>
                      <p class="text-center">
                          Our value to your business.customers Ontime Cost Effective Best In Class Services
                          innovate and accelerate growth in product and time to market
                          nurture talent
                          strive to enhance Stakeholder value 
                      </p>
                  </div>
              </div>
              
              <div class="card service-card border-0 p-4">
                  <div class="text-center">
                      <span class="fa fa-wrench fa-2x service-icon" aria-hidden="true"></span>
                      <h6 class="text-center text-uppercase font-weight-bolder pb-1">
                          TALENT POOL
                      </h6>
                      <p class="text-center">
                      Technical Leads,<br>
                      Project Managers,<br>
                      Business Domain Experts,<br>
                      Software Engineers,<br>
                      Integration and <br>
                      Test Development,<br>
                      UI Designers,<br>
                      Technical Writers,<br>
                      Support Personnel. 
                      </p>
                  </div>
              </div>
          </div>
        </div>
  <!----------------------------------------------ABOUT US END----------------------------------------------->

  <!-----------------------------------------UNIQUE ABOUT US------------------------------------------------->
      <div class="row mt-3">
          <div class="col-md-6 col-sm-12 col-xs-12 color1" style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
              <div class="p-3">
                  <h5 class="text-dark-blue font-weight-bold text-center">
                    <u>UNIQUE ABOUT US</u>
                  </h5>
                  <p class="text-dark-blue"> 
                      We Offer<b class="b">Cost Effective Solutions </b>to the customers based on their current need and future demand.
                  </p>
                  <p class="text-dark-blue "> 
                      We take our Commitment very seriously – <b class="b">On Time Delivery</b>.
                  </p>
                  <p class="text-dark-blue ">
                      Team of <b class="b">Skilled experts</b> in each domain are available for Consultation.
                  </p>
                  <p class="text-dark-blue ">
                      <b class="b">24 x 7</b> x 365 Support.
                  </p>
                  <p class="text-dark-blue ">
                      Dedicated <b class="b"> Domain Experts</b> assigned to each vertical to have dedicated focus.
                  </p>
                  <p class="text-dark-blue ">
                      On going <b class="b"> Training </b>to both employees and customers.
                  </p>
                  <p class="text-dark-blue ">
                      which enables our products <b class="b">Accelerated Time to Market</b>.
                  </p>
                  <p class="text-dark-blue ">
                      Offer Industry <b class="b">Best practices and suggestions </b> to drive Revenue growth.
                  </p>
                  <p class="text-dark-blue ">
                      Offer Internship to college grads with a strong Will to Win.
                  </p>
              </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12" style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
              <img src="./../../assets/img/about-us/rb_18771.png" alt="img" 
              style="width:100%;height:100%;padding-left: 0px; padding-right: 0px; margin-left: 0px;margin-bottom: 0px;
              margin-right: 0px;">
          </div>
      </div>
  </div>
</div>
 <!---------------------------------------UNIQUE ABOUT US END------------------------------------------------->
</div>