<!-- <div class="row">
	<div class="col-md-12 col-sm-12 col-12 ">
		<div class="alert-content justify-content-end">
			<div class="alert alert-danger alert-dismissible fade show" role="alert">
				<div class="h3 font-weight-bold text-uppercase">
					We're Hiring
				</div>
				<div class="lead text-capitalize">
					<div>
						<i class="fa fa-dot-circle-o" aria-hidden="true"></i>
						VB.NET
					</div>
					<div>
						<i class="fa fa-dot-circle-o" aria-hidden="true"></i>
						.NET
					</div>
					<div>
						<i class="fa fa-dot-circle-o" aria-hidden="true"></i>
						C#
					</div>
					<div class="text-uppercase">
						<i class="fa fa-dot-circle-o" aria-hidden="true"></i>
						Marketing
					</div>
				</div>
				<button type="button" class="close" data-dismiss="alert" aria-label="Close" title="close">
					<span class="text-danger" aria-hidden="true">
						<i class="fa fa-times-circle-o" aria-hidden="true"></i>
					</span>
				</button>
			</div> 
		</div>
	</div>
</div> -->


<div class=" d-none d-sm-block">
	<div class="header-gradient p-2">
		<div class="card product-card ">
			<div class="card-body d-none d-sm-block animatable bounceInLeft">
				<h1 class="text-light">
					        Annaa Silicon Technology Private Limited
					<sup style=" border-radius: 50%; padding: 0.3%;">
						<i class= "fa fa-registered" aria-hidden="true"></i>
					</sup>
				</h1>
				<h5 class="text-light font-italic company">
					Technology Makers Pleasure
				</h5>
			</div>
			<div class="card-body d-block d-sm-none animatable bounceInLeft">
				<h4 class="text-light">
					Annaa Silicon Technology Private Limited
					<sup style="border: 2px solid #fff; border-radius: 50%; padding: 0.3%;">
						<i class="fa fa-registered" aria-hidden="true"></i>
					</sup>
				</h4>
				<h6 class="text-light font-italic company">
					Technology Makes Pleasure
				</h6>
			</div>
			<br />
			<div class="d-none d-sm-block">
				<div class="row pb-3 animatable bounceIn">
					<div class="col-md-2 col-sm-12 col-xs-8 text-center">
						<img src="./../../assets/img/home/icon/work.png" class="rounded pb-2" style="width: 90%;"
							alt="ICON" />
						<h5 class="text-center text-light">Work Collaboration</h5>
					</div>
					<div class="col-md-2 col-sm-12 col-xs-12 text-center">
						<img src="./../../assets/img/home/icon/messages.png" class="rounded pb-2" style="width: 90%;"
							alt="ICON" />
						<h5 class="text-center text-light">Bulk SMS Gateway</h5>
					</div>
					<div class="col-md-2 col-sm-12 col-xs-12 text-center">
						<img src="./../../assets/img/home/icon/barcode.png" class="rounded pb-2"
							style="width: 90%;" alt="ICON" />
						<h5 class="text-center text-light">Barcode Solutions</h5>
					</div>
					<div class="col-md-2 col-sm-12 col-xs-12 text-center">
						<img src="./../../assets/img/home/icon/website.png" class="rounded pb-2" style="width: 90%;"
							alt="ICON" />
						<h5 class="text-center text-light">Web Application</h5>
					</div>
					<div class="col-md-2 col-sm-12 col-xs-12 text-center">
						<img src="./../../assets/img/home/icon/mobile.png" class="rounded pb-2"
							style="width: 90%;" alt="ICON" />
						<h5 class="text-center text-light">Mobile Applications</h5>
					</div>
					<div class="col-md-2 col-sm-12 col-xs-12 text-center">
						<img src="./../../assets/img/home/icon/pay.png" class="rounded pb-2" style="width: 90%;"
							alt="ICON" />
						<h5 class="text-center text-light">Payroll Management</h5>
					</div>
				</div>
			</div>
			<div class="row pb-3 w3-animate-left d-block d-sm-none">
				<div class="col-md-2 col-sm-12 col-xs-8 text-center">
					<img src="./../../assets/img/home/icon/work.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Work Collaboration</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/messages.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Bulk SMS Gateway</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/barcode.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Barcode Solutions</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/website.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Web Application</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/mobile.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Mobile Applications</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/payroll.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Payroll Management</h5>
				</div>
			</div>
		</div>
	</div>

	<!--	-------  Our Services	-------	 -->

	<div class="p-4">
		<div class="container-fluid">
			<div class="container row">
				<div class="border border-bottom-0 border-left-0 border-top-0 p-2 d-none d-sm-block">
					<span class="fa fa-cogs head-icon"></span>
				</div>
				<h2 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
					services we provider
				</h2>
				<h5 class="text-center text-dark mb-4 font-weight-bolder text-uppercase p-2 d-block d-sm-none">
					services we provider
				</h5>
			</div>
			<br />
			<div class="row">
				<div class="col-md-3 col-sm-12 col-xs-12  animatable bounceInLeft">
					<div class="card service-card border-0 p-4 ">
						<div class="text-center">
							<span class="fa fa-globe service-icon" aria-hidden="true"></span>

							<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
								Web Development
							</h6>
							<p class="text-justify text-muted">
								Empower your business with a website that works as hard as you do. Whether you’re building your brand, boosting sales, or streamlining services, we deliver custom web solutions designed to drive results.
							</p><br />
							<button class="btn service-btn text-white mt-3 pt-2 pb-2" routerLink="/web-development">
								Read More
							</button>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-12 col-xs-12  animatable bounceIn">
					<div class="card service-card border-0 p-4">
						<div class="text-center">
							<span class="fa fa-android service-icon" aria-hidden="true"></span>
							<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
								App Development
							</h6>
							<p class="text-justify text-muted">
								Transform your ideas into powerful, user-friendly apps. We craft custom mobile solutions that drive engagement, streamline processes, and bring your vision to life on every platform.
							</p><br>
							<button class="btn service-btn text-white mt-3 pt-2 pb-2" routerLink="/mobile-development">
								Read More
							</button>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-12 col-xs-12 animatable bounceIn">
					<div class="card service-card border-0 p-4">
						<div class="text-center">
							<span class="fa fa-wrench service-icon" aria-hidden="true"></span>
							<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
								App Maintenance
							</h6>
							<p class="text-justify text-muted">
								Keep your app running at peak performance. From updates to troubleshooting, we provide reliable maintenance solutions to ensure seamless functionality, security, and user satisfaction.
							</p><br>
							<button class="btn service-btn text-white mt-3 pt-2 pb-2" routerLink="/maintenance">
								Read More
							</button>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-12 col-xs-12  animatable bounceInRight">
					<div class="card service-card border-0 p-4">
						<div class="text-center">
							<span class="fa fa-server service-icon" aria-hidden="true"></span>
							<h6 class="text-center text-dark text-uppercase font-weight-bolder pb-1">
								Web Hosting
							</h6>
							<p class="text-justify text-muted">
								Reliable, secure, and fast web hosting tailored to your needs. Empower your online presence with hosting solutions that ensure uptime, scalability, and unmatched performance.
							</p><br>
							<div class="row justify-content-center">
								<div class="col-md-10 col-sm-12 col-12 text-center">
									<button class="btn service-btn text-white mt-3 pt-2 pb-2" routerLink="/web-hosting">
										Read More
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br />
	</div>

	<!--	------	 About US	----------	 -->
	<div class="row col  text-center">
		<div class="col-md-6 col-sm-12 col-xs-12  bounceInLeft"
			style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
			<img src="./../../assets/img/home/rb_1592.png" style="width: 100%;" />
		</div>

		<div class="color col-md-6 col-sm-12 col-xs-12  bounceInRight">
			<div class="row text-right">
				<div class="border border-bottom-0 border-left-0 border-top-0 p-2  d-none d-sm-block">
					<span class="fa fa-user head-icon"></span>
				</div>
				<h2 class="text-center text-dark-blue mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
					Little About Us
				</h2>
				<h5 class="text-center text-dark-blue font-weight-bolder text-uppercase p-2 d-block d-sm-none">
					Little About Us
				</h5>
			</div>
			<p class="text-dark-blue text-justify" style="font-size: large;">
				Annaa Silicon Technology is a complete IT Services company with major
				focus on Retail, religion and Health Verticals. The Company is head quartered in Kumbakonam, India
				and recently expanded its operations in Chennai, India. The Company is mentored by Domain Experts and
				entrepreneurs from Phoenix, USA and UK. The Company has been
				delivering end-to-end software development, quality assurance and Annual maintenance support solutions
				to
				clients ranging from Fortune 1000 companies to startups in all
				stages of funding since 2003.Our mission is to provide high value IT solutions and services to our
				customers
				with distinctive competence and integrity by working together
				for the shared long term objectives.
			</p>
			<div class="col-xs-12 text-right pr-3 d-none d-sm-block">
				<button type="button" class="btn btn-outline btn-read waves-effect p-2" routerLink="/about-us">
					Read More
					<span class="fa fa-arrow-right"></span>
				</button>
			</div>
			<div class="col-xs-12 text-center mb-3 pr-3 d-block d-sm-none">
				<button type="button" class="btn btn-outline btn-read waves-effect p-2" routerLink="/about-us">
					Read More
					<span class="fa fa-arrow-right"></span>
				</button>
			</div>
		</div>
	</div>

	<!--	-------	 Our Products	----------	 -->
	<div class="statistic-section-bg p-2">
		<div class="container-fluid animatable moveUp">
			<div class="container row">
				<div class="border border-bottom-0 border-left-0 border-top-0 p-2 d-none d-sm-block">
					<span class="fa fa-graduation-cap section-icon"></span>
				</div>
				<!-- <h2 class="text-center text-light mb-4 font-weight-bolder text-uppercase p-2">
					Our Achivements
				</h2> -->
				<h2 class="text-center text-light mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
					Our Achivements
				</h2>
				<h5 class="text-center text-light  font-weight-bolder text-uppercase p-2 d-block d-sm-none">
					Our Achivements
				</h5>
			</div>
			<section id="statistic" class="statistic-section one-page-section pb-2">
				<div class="container pb-3">
					<div class="row text-center">
						<div class="col-xs-12 col-md-3">
							<div class="counter">
								<i class="fa fa-coffee fa-2x stats-icon"></i>
								<h2 class="timer count-title count-number">999</h2>
								<div class="stats-line-black"></div>
								<h5 class="stats-text">Coffee Cups</h5>
							</div>
						</div>
						<div class="col-xs-12 col-md-3">
							<div class="counter">
								<i class="fa fa-code fa-2x stats-icon"></i>
								<h2 class="timer count-title count-number">10000
								</h2>
								<div class="stats-line-black"></div>
								<h5 class="stats-text">Line Code</h5>
							</div>
						</div>
						<div class="col-xs-12 col-md-3">
							<div class="counter">
								<i class="fa fa-clock-o fa-2x stats-icon"></i>
								<h2 class="timer count-title count-number">17</h2>
								<div class="stats-line-black"></div>
								<h5 class="stats-text">Years Experience</h5>
							</div>
						</div>
						<div class="col-xs-12 col-md-3">
							<div class="counter">
								<i class="fa fa-laptop fa-2x stats-icon"></i>
								<h2 class="timer count-title count-number">1035</h2>
								<div class="stats-line-black"></div>
								<h5 class="stats-text">Project</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>

	<!--	-------  Our Services	-------	 -->

	<div class="color p-4 animatable bounceIn">
		<div class="container row">
			<div class="border border-bottom-0 border-left-0 border-top-0 p-2 d-none d-sm-block">
				<span class="fa fa-globe head-icon"></span>
			</div>
			<!-- <h2 class="text-center text-light mb-4 font-weight-bolder text-uppercase p-2">
				OUR WEB APPLICATIONS
			</h2> -->
			<h2 class="text-center text-dark-blue mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
				OUR WEB APPLICATIONS
			</h2>
			<h5 class="text-center text-dark-blue  font-weight-bolder text-uppercase p-2 d-block d-sm-none">
				OUR WEB APPLICATIONS
			</h5>
		</div>
		<br />
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-12 col-sm-12 col-12">
					<div id="demo" class="carousel slide" data-ride="carousel">
						<!-- Indicators -->
						<!-- <ul class="carousel-indicators">
						<li data-target="#demo" data-slide-to="0" class="active"></li>
						<li data-target="#demo" data-slide-to="1"></li>
						<li data-target="#demo" data-slide-to="2"></li>
						</ul> -->

						<!-- The slideshow -->
						<div class="carousel-inner">
							<div class="carousel-item active">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-shopping-cart app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											Retail Management
										</div>
										<div class="app-desc text-dark-blue">
											A retail management system (RMS) is a platform that combines several useful
											tools to aid in running a retail store or chain, such as inventory
											management,
											point of sale (POS), and customer relationship management (CRM). ... Some
											platforms will even have marketing and analytics tools to help you improve
											your
											business.
										</div>
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-university app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											School Management
										</div>
										<div class="app-desc text-dark-blue">
											School management software is a tool that is specifically designed to
											streamline
											the paperless administration of schools and educational institutions. It
											consists of various modules that greatly help the teachers and staff in
											maintaining student records, academic history, and other essential student
											information.
										</div>
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-medkit app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											Hospital Management
										</div>
										<div class="app-desc text-dark-blue">
											The hospital management system (HMS) is an integrated software that handles
											different directions of clinic workflows. It manages the smooth healthcare
											performance along with administrative, medical, legal and financial control.
											That is a cornerstone for the successful operation of the healthcare
											facility.
										</div>
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-building app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											Hotel Management
										</div>
										<div class="app-desc text-dark-blue">
											A hotel management software is a platform with which an independent hotel or
											a
											hotel group can automate all their operations. This software streamlines
											various
											tasks of your hotel such as front desk, billing, revenue management and much
											more.
										</div>
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-hand-o-right app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											For our more web applicaton and softwares click below
										</div>
										<div class="app-desc text-dark-blue">
											<button class="btn btn-outline btn-read waves-effect p-2" routerLink="/ourproducts">
												Know More
											</button>
										</div>
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
						</div>

						<!-- Left and right controls -->
						<a class="carousel-control-prev text-dark" href="#demo" data-slide="prev">
							<span aria-hidden="true">
								<svg xmlns="http://www.w3.org/2000/svg" fill="darkblue" viewBox="0 0 16 16" width="32" height="32">
									<path fill-rule="evenodd" d="M11.354 3.354a.5.5 0 0 1 0 .707L7.707 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0z"/>
								</svg>
							</span>
						</a>
						<a class="carousel-control-next text-dark" href="#demo" data-slide="next">
							<span aria-hidden="true">
								<svg xmlns="http://www.w3.org/2000/svg" fill="darkblue" viewBox="0 0 16 16" width="32" height="32">
									<path fill-rule="evenodd" d="M4.646 3.354a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L8.293 8 4.646 4.354a.5.5 0 0 1 0-.708z"/>
								</svg>
							</span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<br />
	</div>
</div>



<!-- <div class="col-12">
	<a href="#" id="scroll" style="display: none;"><span></span></a>
</div> -->


<!--	MOBILE VIEW -->
<div class=" d-block d-sm-none">
	<div class="header-gradient p-2">
		<div class="card product-card ">
			<div class="card-body d-block d-sm-none">
				<h4 class="text-light p-2">
					Annaa Silicon Technology Private Limited <sub> </sub>
					<!-- <sup style="border: 2px solid #fff; border-radius: 50%; padding: 0.5%; margin: 1%;"> -->
						<i class="fa fa-registered fa-1x" aria-hidden="true" style="padding: 0.3%; margin: 1%;"></i>
					<!-- </sup> -->
				</h4>
				<h6 class="text-light font-italic company">
					Technology Makers Pleasure
				</h6>
			</div>

			<div class="row pb-3 w3-animate-left">
				<div class="col-md-2 col-sm-12 col-xs-8 text-center">
					<img src="./../../assets/img/home/icon/work.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Work Collaboration</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/messages.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Bulk SMS Gateway</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/barcode.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Barcode Solutions</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/website.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Web Application</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/mobile.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Mobile Applications</h5>
				</div>
				<div class="col-md-2 col-sm-12 col-xs-12 text-center">
					<img src="./../../assets/img/home/icon/pay.png" class="rounded pb-2" style="width: 70%;"
						alt="ICON" />
					<h5 class="text-center text-light">Payroll Management</h5>
				</div>
			</div>
		</div>
	</div>

	<!--	-------  Our Services	-------	 -->

	<div class="p-4">
		<div class="container-fluid">
			<div class="container row">
				<div class="border border-bottom-0 border-left-0 border-top-0 p-2 d-none d-sm-block">
					<span class="fa fa-cogs head-icon"></span>
				</div>
				<h2 class="text-center text-dark-blue mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
					services we provider
				</h2>
				<h5 class="text-center text-dark-blue mb-4 font-weight-bolder text-uppercase p-2 d-block d-sm-none">
					services we provider
				</h5>
			</div>
			<br />
			<div class="row">
				<div class="col-md-3 col-sm-12 col-xs-12">
					<div class="card service-card border-0 p-4">
						<div class="text-center">
							<span class="fa fa-globe service-icon" aria-hidden="true"></span>

							<h6 class="text-center text-dark-blue text-uppercase font-weight-bolder pb-1">
								Web Development
							</h6>
							<p class="text-left text-muted">
								Empower your business with a website that works as hard as you do. Whether you’re building your brand, boosting sales, or streamlining services, we deliver custom web solutions designed to drive results.
							</p><br />
							<button class="btn service-btn  mt-3 pt-2 pb-2" routerLink="/web-development">
								Read More
							</button>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-12 col-xs-12">
					<div class="card service-card border-0 p-4">
						<div class="text-center">
							<span class="fa fa-android service-icon" aria-hidden="true"></span>
							<h6 class="text-center text-dark-blue text-uppercase font-weight-bolder pb-1">
								App Development
							</h6>
							<p class="text-left text-dark-blue text-muted">
								Transform your ideas into powerful, user-friendly apps. We craft custom mobile solutions that drive engagement, streamline processes, and bring your vision to life on every platform.
							</p><br>
							<button class="btn service-btn text-white mt-3 pt-2 pb-2" routerLink="/mobile-development">
								Read More
							</button>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-12 col-xs-12">
					<div class="card service-card border-0 p-4">
						<div class="text-center">
							<span class="fa fa-wrench service-icon" aria-hidden="true"></span>
							<h6 class="text-center text-dark-blue text-uppercase font-weight-bolder pb-1">
								App Maintenance
							</h6>
							<p class="text-left  text-muted">
								Keep your app running at peak performance. From updates to troubleshooting, we provide reliable maintenance solutions to ensure seamless functionality, security, and user satisfaction.
							</p><br>
							<button class="btn service-btn text-white mt-3 pt-2 pb-2" routerLink="/maintenance">
								Read More
							</button>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-sm-12 col-xs-12">
					<div class="card service-card border-0 p-4">
						<div class="text-center">
							<span class="fa fa-server service-icon" aria-hidden="true"></span>
							<h6 class="text-center text-dark-blue text-uppercase font-weight-bolder pb-1">
								Web Hosting
							</h6>
							<p class="text-left  text-muted">
								Reliable, secure, and fast web hosting tailored to your needs. Empower your online presence with hosting solutions that ensure uptime, scalability, and unmatched performance.
							</p><br>
							<div class="row justify-content-center">
								<div class="col-md-10 col-sm-12 col-12 text-center">
									<button class="btn service-btn text-white mt-3 pt-2 pb-2" routerLink="/web-hosting">
										Read More
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br />
	</div>

	<!--	------	 About US	----------	 -->
	<div class="row col  text-center">
		<div class="col-md-6 col-sm-12 col-xs-12"
			style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
			<img src="./../../assets/img/home/rb_1592.png" style="width: 100%;" />
		</div>

		<div class="color col-md-6 col-sm-12 col-xs-12">
			<div class="row text-right">
				<div class="border border-bottom-0 border-left-0 border-top-0 p-2  d-none d-sm-block">
					<span class="fa fa-user head-icon"></span>
				</div>
				<h2 class="text-center text-dark-blue mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
					Little About Us
				</h2>
				<h5 class="text-center text-dark-blue  font-weight-bolder text-uppercase p-2 d-block d-sm-none">
					Little About Us
				</h5>
			</div>
			<p class="text-dark-blue text-justify">
				Annaa Silicon Technology is a complete IT Services company with major
				focus on Retail, religion and Health Verticals. The Company is head quartered in Kumbakonam, India
				and recently expanded its operations in Chennai, India. The Company is mentored by Domain Experts and
				entrepreneurs from Phoenix, USA and London UK.The Company has been
				delivering end-to-end software development, quality assurance and Annual maintenance support solutions
				to
				clients ranging from Fortune 1000 companies to startups in all
				stages of funding since 2003.Our mission is to provide high value IT solutions and services to our
				customers
				with distinctive competence and integrity by working together
				for the shared long term objectives.
			</p>
			<div class="col-xs-12 text-center mb-3 pr-3 d-block d-sm-none">
				<button type="button" class="btn btn-read btn-outline-primary waves-effect p-2" routerLink="/about-us">
					Read More
					<span class="fa fa-arrow-right"></span>
				</button>
			</div>
		</div>
	</div>

	<!--	-------	 Our Products	----------	 -->
	<div class="statistic-section-bg p-2">
		<div class="container-fluid">
			<div class="container row">
				<div class="border border-bottom-0 border-left-0 border-top-0 p-2 d-none d-sm-block">
					<span class="fa fa-trophy section-icon"></span>
				</div>
				<!-- <h2 class="text-center text-light mb-4 font-weight-bolder text-uppercase p-2">
					Our Achivements
				</h2> -->
				<h2 class="text-center text-light mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
					Our Achivements
				</h2>
				<h5 class="text-center text-light  font-weight-bolder text-uppercase p-2 d-block d-sm-none">
					Our Achivements
				</h5>
			</div>
			<section id="statistic" class="statistic-section one-page-section pb-2">
				<div class="container pb-3">
					<div class="row text-center">
						<div class="col-xs-12 col-md-3">
							<div class="counter">
								<i class="fa fa-coffee fa-2x stats-icon"></i>
								<h2 class="timer count-title count-number">999</h2>
								<div class="stats-line-black"></div>
								<h5 class="stats-text">Coffee Cups</h5>
							</div>
						</div>
						<div class="col-xs-12 col-md-3">
							<div class="counter">
								<i class="fa fa-code fa-2x stats-icon"></i>
								<h2 class="timer count-title count-number">10000
								</h2>
								<div class="stats-line-black"></div>
								<h5 class="stats-text">Line Code</h5>
							</div>
						</div>
						<div class="col-xs-12 col-md-3">
							<div class="counter">
								<i class="fa fa-clock-o fa-2x stats-icon"></i>
								<h2 class="timer count-title count-number">17</h2>
								<div class="stats-line-black"></div>
								<h5 class="stats-text">Years Experience</h5>
							</div>
						</div>
						<div class="col-xs-12 col-md-3">
							<div class="counter">
								<i class="fa fa-laptop fa-2x stats-icon"></i>
								<h2 class="timer count-title count-number">1035</h2>
								<div class="stats-line-black"></div>
								<h5 class="stats-text">Project</h5>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>

	<!--	-------  Our Services	-------	 -->

	<div class="color p-4">
		<div class="container-fluid row">
			<div class="border border-bottom-0 border-left-0 border-top-0 p-2 d-none d-sm-block">
				<span class="fa fa-dashboard head-icon"></span>
			</div>
			<!-- <h2 class="text-center text-light mb-4 font-weight-bolder text-uppercase p-2">
				OUR WEB APPLICATIONS
			</h2> -->
			<h2 class="text-center text-dark-blue mb-4 font-weight-bolder text-uppercase p-2 d-none d-sm-block">
				OUR WEB APPLICATIONS
			</h2>
			<h5 class="text-center text-dark-blue font-weight-bolder text-uppercase p-2 d-block d-sm-none">
				OUR WEB APPLICATIONS
			</h5>
		</div>
		<br />
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col-md-12 col-sm-12 col-12">
					<div id="demo1" class="carousel slide" data-ride="carousel1">
						<!-- Indicators -->
						<!-- <ul class="carousel-indicators">
						<li data-target="#demo" data-slide-to="0" class="active"></li>
						<li data-target="#demo" data-slide-to="1"></li>
						<li data-target="#demo" data-slide-to="2"></li>
						</ul> -->

						<!-- The slideshow -->
						<div class="carousel-inner">
							<div class="carousel-item active">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-shopping-cart app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											Retail Management
										</div>
										<!-- <div class="app-desc text-light">
											A retail management system (RMS) is a platform that combines several useful
											tools to aid in running a retail store or chain, such as inventory management,
											point of sale (POS), and customer relationship management (CRM). ... Some
											platforms will even have marketing and analytics tools to help you improve your
											business.
										</div> -->
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-university app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											School Management
										</div>
										<!-- <div class="app-desc text-light">
											School management software is a tool that is specifically designed to streamline
											the paperless administration of schools and educational institutions. It
											consists of various modules that greatly help the teachers and staff in
											maintaining student records, academic history, and other essential student
											information.
										</div> -->
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-medkit app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											Hospital Management
										</div>
										<!-- <div class="app-desc text-light">
											The hospital management system (HMS) is an integrated software that handles
											different directions of clinic workflows. It manages the smooth healthcare
											performance along with administrative, medical, legal and financial control.
											That is a cornerstone for the successful operation of the healthcare facility.
										</div> -->
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-building app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											Hotel Management
										</div>
										<!-- <div class="app-desc text-light">
											A hotel management software is a platform with which an independent hotel or a
											hotel group can automate all their operations. This software streamlines various
											tasks of your hotel such as front desk, billing, revenue management and much
											more.
										</div> -->
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
							<div class="carousel-item">
								<div class="row justify-content-center">
									<div class="col-md-8 col-sm-12 col-12 text-center">
										<span class="fa fa-hand-o-right app-icon" aria-hidden="true"></span>
									</div>
									<div class="col-md-12 col-sm-12 col-12">
										<div class="app-title text-dark-blue">
											For our more web applicaton and softwares click below
										</div>
										<div class="app-desc text-dark-blue">
											<button class="btn btn-outline btn-read waves-effect p-2" routerLink="/ourproducts">
												Know More
											</button>
										</div>
										<!-- <div class="col-md-4 col-sm-8 col-12 colapp-link">
											Know More
										</div> -->
									</div>
								</div>
							</div>
						</div>

						<!-- Left and right controls -->
						<a class="carousel-control-prev text-dark" href="#demo1" data-slide="prev">
							<span aria-hidden="true">
								<svg xmlns="http://www.w3.org/2000/svg" fill="darkblue" viewBox="0 0 16 16" width="32" height="32">
									<path fill-rule="evenodd" d="M11.354 3.354a.5.5 0 0 1 0 .707L7.707 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0z"/>
								</svg>
							</span>
						</a>
						<a class="carousel-control-next text-dark" href="#demo1" data-slide="next">
							<span aria-hidden="true">
								<svg xmlns="http://www.w3.org/2000/svg" fill="darkblue" viewBox="0 0 16 16" width="32" height="32">
									<path fill-rule="evenodd" d="M4.646 3.354a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L8.293 8 4.646 4.354a.5.5 0 0 1 0-.708z"/>
								</svg>
							</span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<br />
	</div>
</div>

<!-- 	---------	AUTO MODEL	----------	  -->
<!-- <div class="modal fade" id="myModal">
	<div class="modal-dialog modal-lg">
		<div class="modal-content"> -->
			<!-- Modal body  -->
			<!-- <div class="modal-body">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
				<div class="modal-body-content">
					<div class="row">
						<div class="col-md-4 col-sm-12 col-xs-12">
							<div class="profile-sidebar text-center">
								<img src="./../../assets/img/home/md.png" class="rounded-circle modal-img" alt="" />
							</div>
						</div>
						<div class="col-md-8 col-sm-12 col-xs-12 text-center">
							<div class="pt-5">
								<h2 class="font-weight-bolder">K.Gnanaguru</h2>
								<h3 class="font-weight-normal">Managing Director</h3>
								<h5 style="color: black;">
									<i class="fa fa-phone" aria-hidden="true">&nbsp;95146 87687, 9344303015</i>
								</h5>
								<h5 style="color: black;">
									<i class="fa fa-phone" aria-hidden="true">&nbsp;9843683020, 95433 61507</i>
								</h5>
							</div>
						</div>
					</div>
				</div> -->
				<!---------------------------------------	XS_VIEW		--------------------------->
			<!-- </div>
		</div>
	</div>
</div> -->