<div class="p-2 text-white bg text-center" style="margin-bottom:0;">
	<div class="col-12">
		<h3 class="text-left">Get In Touch</h3>
	</div>
</div>
<div class="p-3 bg text-white text-center" style="margin-bottom:0">
	<div class="container-fluid col-xs-12 mb-3" style="width: 100%;">
		<div class="row">
			<!--<div class="col-md-4 col-sm-12 col-xs-12  text-left">
				<img src="./../../assets/img/logo.png" style="width: 75%;">
					 <p>
                        Annaa Silicon Technology is a complete IT Services company with major focus on Retail, religion 
                        and Health Verticals. The Company is head quartered in Kumbakonam, India and recently expanded 
                        its operations in Chennai, India. The Company is mentored by Domain Experts and entrepreneurs 
                        from Phoenix, USA and London UK.
                    </p> 
			</div> -->
			<div class="col-md-2 col-sm-12 col-xs-12  text-left">
				<h4 class="text-uppercase font-weight-bold">industries</h4>
				<hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 70px;">
				<h5><a class="text-light font-weight-light">Retail</a></h5>
				<h5><a class="text-light font-weight-light">Healthcare</a></h5>
				<h5><a class="text-light font-weight-light">Education</a></h5>
				<h5><a class="text-light font-weight-light">Temple Softwares</a></h5>
				<h5><a class="text-light font-weight-light"> Value Added Services</a></h5>
				<h5><a class="text-light font-weight-light">Hospitality</a></h5>
				<h5><a class="text-light font-weight-light">Finance & Chit</a></h5>
				<br><br>
			</div>
			<div class="col-md-4 col-sm-12 col-xs-12  text-left">
				<h4 class="text-uppercase font-weight-bold">services</h4>
				<hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 70px;">
				<h5><a routerLink="/custom-software-development" class=" service-text font-weight-light">Custom Software
						Development</a></h5>
				<h5><a routerLink="/web-development" class="service-text font-weight-light">Web Development, Design and
						ReEngineering</a></h5>
				<h5><a routerLink="/mobile-development" class="service-text font-weight-light"> Mobile Application
						Development</a></h5>
				<h5><a routerLink="/web-hosting" class="service-text font-weight-light"> Web Hosting, SEO Services</a>
				</h5>
				<h5><a routerLink="/maintenance" class="service-text font-weight-light"> Application Maintenance &
						Support</a></h5>
			</div>
			<div class="col-md-2 col-sm-12 col-xs-12  text-left">
				<h4 class="text-uppercase font-weight-bold">company</h4>
				<hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 70px;">
				<h5><a routerLink="/about-us" class="service-text font-weight-light">About Us</a></h5>
				<h5><a routerLink="/custom-software-development" class="service-text font-weight-light">Services</a>
				</h5>
				<h5><a routerLink="/verticals" class="service-text font-weight-light">Verticals</a></h5>
				<h5><a routerLink="/ourproducts" class="service-text font-weight-light">Our Products</a></h5>
				<h5><a routerLink="/testimonials" class="service-text font-weight-light">Testimonials</a></h5>
				<!-- <h5><a routerLink="/upload-cv" class="text-primary font-weight-light">Careers</a></h5> -->
				<h5><a routerLink="/contact-us" class="service-text font-weight-light">Contact Us</a></h5>
			</div>
			<div class="col-md-4 col-sm-12 col-xs-12  text-left">
				<h4 class="text-uppercase font-weight-bold">contact us</h4>
				<hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 70px;">
				<!-- <img src="./../../assets/img/brand.png"> -->
				<h5 class="text-light font-weight-light"><i class="fa fa-home mr-3 text-light foot-fa"></i>No:44 A/47
					Kumaragam 2nd Floor, Abimukeswarar East Street, Kumbakonam</h5>
				<h5 class="mail-text font-weight-light"> </h5>
				<h5 class="mail-text font-weight-light">
					<i class="fa fa-envelope mr-3 text-light foot-fa"></i><br>
					<a href="mailto:info@annaasilicontechnology.com" class="mail-text">info@annaasilicontechnology.com</a>
				</h5>
				<h5 class="mail-text font-weight-light">
					<a href="mailto:support@annaasilicontechnology.com" class="mail-text">support@annaasilicontechnology.com</a>
				</h5>
				<h5 class="mail-text font-weight-light">
					<a href="mailto:hr@annaasilicontechnology.com" class="mail-text">hr@annaasilicontechnology.com</a>
				</h5>
				<h5 class="mail-text font-weight-light">
					<a href="mailto:sales@annaasilicontechnology.com" class="mail-text">sales@annaasilicontechnology.com</a>
				</h5>
				<h5 class="mail-text font-weight-light">
					<a href="mailto:annaasilicontechnology@gmail.com" class="mail-text">annaasilicontechnology@gmail.com</a>
				</h5>
				
				<!-- <h5 class="text-light font-weight-light"><i class="fas fa-phone mr-3 text-light"></i> +91 9344303015, +91 9843683020</h5> -->
				<h5 class="text-light font-weight-light">
					<i class="fa fa-mobile mr-3 text-light foot-fa" aria-hidden="true"></i>
					<a href="tel:+919344303015" class="mail-text">+91 9344303015</a>, 
					<a href="tel:+919514687874" class="mail-text">+91 9514687874</a>
				</h5>
				<h5 class="text-light font-weight-light">
					<i class="fa fa-phone mr-3 text-light foot-fa"></i>
					<a href="tel:+914352427274" class="mail-text">0435 2427274</a>
				</h5>
				
			</div>
		</div>
	</div>

	<div class="h4 text-left">
		Powered By-
		<a href="#" target="_blank" class="text-danger font-weight-light">
			 Ramalingar Visuals
		</a>
	</div>

	<div class="row border border-left-0 border-left-0 border-secondary">
		<div class="col-md-6 col-sm-6 col-xs-12 d-none d-sm-block">
			<h5 class="mt-2 pt-3  text-left ">
				<i class="fa fa-copyright"></i>
				2024 Annaa Silicon Technology Private Limited
				<sup style=" font-size: 12px; padding: 0.3%;">
					<i class="fa fa-registered fa-1x" aria-hidden="true"></i>
				</sup> - All Rights Reserved
			</h5>
		</div>
		<div class="col-md-6 col-sm-6 col-xs-12 d-block d-sm-none">
			<h6 class="mt-1 pt-2 text-left">
				<i class="fa fa-copyright" aria-hidden="true"></i>
				2024 Annaa Silicon Technology Pvt Ltd., <br>  <div style="text-align: center;">All Rights Reserved</div>
			</h6>
		</div>
		<div class="col-md-6 col-sm-12 col-xs-12 text-center">
			<div id="social">
				<a class="fa fa-facebook fa-lg service-text" target="_blank" href="https://www.facebook.com/annaaSiliconInfo/"></a>&nbsp;
				<a class="fa fa-twitter fa-lg service-text" target="_blank" href="https://twitter.com/annasilicon"></a>&nbsp;
				<a class="fa fa-google fa-lg service-text" target="_blank" href="#"></a>&nbsp;
				<a class="fa fa-linkedin fa-lg service-text" target="_blank" href="https://www.linkedin.com/company/annaa-silicon-technology-private-limited/"></a>&nbsp;
				<a class="fa fa-whatsapp fa-lg service-text" target="_blank" href="https://wa.me/+91 9514687874?text=Hello%20there!%20I%20want%20to%20know%20more%20about%20your%20services."></a>&nbsp;
				<!-- <a class="skypeBtn smGlobalBtn" target="_blank" href="#"></a>&nbsp;  it does not need -->
				<!-- <a class="pinterestBtn smGlobalBtn" href="#" ></a>&nbsp;
				<a class="tumblrBtn smGlobalBtn" href="#" ></a>&nbsp;
				<a class="rssBtn smGlobalBtn" href="#" ></a>&nbsp; -->
			</div>
		</div>
	</div>

	<div class=" col-xs-12 text-center d-block d-sm-none" style="border-top: 1px solid #f5f5f5;">
		<p class="mt-2">Designed by</p>
		<a href="www.annaasilicontechnology.com" target="_blank" class="font-weight-bolder text-center">
			Annaa Silicon Technology Pvt Ltd.,
			<sup>
				<i class="fa fa-registered" aria-hidden="true"></i>
			</sup>
		</a>
		<br>
		<!-- <p class="mt-2">Powered by</p> -->
		<!-- <a href="www.gurumultienterprises" target="_blank" class="font-weight-bolder text-center">
			Guru Multi Enterprises
		</a> -->
	</div>
</div>