<div class=" d-none d-sm-block">
  <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1 d-none d-sm-block">
  <div class="head-card p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      mobile application development
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <span class="sub-menu" routerLink="/web-hosting"> Web Hosting</span>
      </h5>
    </div>
  </div>
 </div>
<!--------------------------------------------------TITLE END-------------------------------------------->
  <div class="bg-light">
  <!-------------------------------------------------------  MOB DEV --------------------------------------------->
  <div class="container-fluid">
      <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
        MOBILE DEVELOPMENT 
      </h4> 
      <div class="row">
        <div class="col-md-5 col-sm-12 col-xs-12 text-center w3-animate-left" style="margin-top: 20px;">
          <div class="card">
            <!-- <div class="top-sec"> -->
              <img src="./../../../assets/img/service/mobile-app-dev/img-04.jpg" style="width:100%;" class="img-fluid">
            <!-- </div> -->
          </div>
        </div>
          <div class="col-md-7 col-sm-12 col-xs-12 w3-animate-right" style="margin-top: 50px;">
            <p class="text-justify text-dark-blue">
              Smartphones and tablets are replacing desktops and laptops, 
              and users are demanding more and more functionality from their portable devices.
              Any software today needs a solid mobile strategy – or it's dead in the water. 
              Annaa Silicon has been a pioneer in mobile software development, creating native mobile apps
              as well as injecting mobile functionality into a range of enterprise software solutions.
              Annaa Silicon is currently developing mobile apps and mobile software 
              components on iOS, Android.
            </p>
            <br />
            <h3 class="text-body">
              Some of the areas of expertise in custom mobile app development include
            </h3>
            <ul>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Mobile Content management and create world class experience right on the hand held device
            </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Push notifications
              </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> e-Commerce Application
              </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Mobile analytics applications
              </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Expand your data systems to the mobile arena
              </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Create apps to make your workforce more efficient
              </p>
            </ul>
          </div>
      </div>
  </div>
  </div>
</div>

<!--------------------------------------- MOBILE VIEW ------------------------------------------------->

<div class=" d-block d-sm-none">
  
  <div class="bg-light">
  <!-------------------------------------------------------  MOB DEV --------------------------------------------->
  <div class="container-fluid">
      <h4 class="font-weight-bolder align-middle text-center text-uppercase d-block d-sm-none">
        MOBILE DEVELOPMENT
      </h4> 
      <div class="row">
        <div class="col-md-5 col-sm-12 col-xs-12 text-center" style="margin-top: 20px;">
          <div class="card">
            <!-- <div class="top-sec"> -->
              <img src="./../../../assets/img/service/mobile-app-dev/img-04.jpg" style="width:100%;" class="img-fluid">
            <!-- </div> -->
          </div>
        </div>
          <div class="col-md-7 col-sm-12 col-xs-12" style="margin-top: 50px;">
            <p>
              Smartphones and tablets are replacing desktops and laptops, 
              and users are demanding more and more functionality from their portable devices.
              Any software today needs a solid mobile strategy – or it's dead in the water. 
              Annaa Silicon has been a pioneer in mobile software development, creating native mobile apps
              as well as injecting mobile functionality into a range of enterprise software solutions.
              Annaa Silicon is currently developing mobile apps and mobile software 
              components on iOS, Android.
            </p>
            <br />
            <h3 class="text-body">
              Some of the areas of expertise in custom mobile app development include
            </h3>
            <ul>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Mobile Content management and create world class experience right on the hand held device
            </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Push notifications
              </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> e-Commerce Application
              </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Mobile analytics applications
              </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Expand your data systems to the mobile arena
              </p>
              <p>
                <i class="fa fa-crosshairs" aria-hidden="true"></i> Create apps to make your workforce more efficient
              </p>
            </ul>
          </div>
      </div>
  </div>
  <!-------------------------------------------------MOB DEV END-------------------------------------------->
  <!---------------------------------------------CARD FOR BENEFIT WITH CAROUSEL--------------------------------->
  <!-- <div class="parallax"></div> -->
  <div class="container py-3">
    <!--------------------------------------------CARD START ----------------------------------------------->
    <!-- <div class="row ">
      <div class="col-md-7 px-3">
        <div class="card-block px-6">
          <h3>
              Areas of expertise in custom mobile app development include
            </h3>
            <p class="card-text" >
            Push notifications<br>
            e-Commerce Application<br>
            Mobile analytics applications<br>
            Expand your data systems to the mobile arena<br>
            Create apps to make your workforce more efficient
            </p>
          </div>
      </div>
      <div class="col-md-5 col-sm-12 col-xs-12">
        
      </div>
    </div>
  </div> -->
  <!-------------------------------------------CARD FOR BENFITS END------------------------------------------->
</div>
 