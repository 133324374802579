<div class="">
    <!------------------------------------------TITLE----------------------------------------------->
 <div class="title-box uk-border-rounded p-1">
    <div class="head-card p-1 inner-title-box">
      <h1 class="title-text text-center font-weight-bold">
        contact us
      </h1>
      <div class="text-right">
        <h5 class="text-light">
          <span class="sub-menu" routerLink="/home-page">Home </span>&nbsp;/&nbsp;
          <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
          <span class="sub-menu" routerLink="/about-us"> About Us</span>
        </h5>
      </div>
    </div>
   </div>
  <!--------------------------------------------------TITLE END-------------------------------------------->

 
<div class="bg-light">
    <div class="container p-4">

        <div class="modal-body">
            <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
            <div class="modal-body-content">
                <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-12 col-xs-12">
                        <div class="profile-sidebar text-center">
                            <img src="./../../assets/img/home/md.png" class="rounded-circle modal-img" alt="" />
                        </div>
                    </div>
                    <div class="col-md-8 col-sm-12 col-xs-12 text-center">
                        <div class="pt-5">
                            <h2 class="font-weight-bolder">K.Gnanaguru</h2>
                            <h3 class="font-weight-normal">Managing Director</h3>
                            <h5 style="color: black;">
                                <i class="fa fa-phone" aria-hidden="true">&nbsp;95146 87687, 9344303015</i>
                            </h5>
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>
        <h4 class="font-weight-bolder align-middle text-center text-uppercase">
            contact-us
          </h4>
        <!-- <div class="card"> -->
            <div class="row ">
                    <div class="col-lg-6 text-center w3-animate-left" style="padding-right: 0px;">
                        <div class="contact-detail">
                            <img src="./../../assets/img/contact/bg.jpg" alt="Notebook" class="contact-img">
                            <div class="content p-4">
                                <br /><br />
                                <h1 class="text-light align-middle" style="color: white;">LOCATION</h1>
                                <h4 class="align-middlefont-weight-bold">Annaa Silicon Technology Private Limited.,
                                    <sup >
                                        <i class="fa fa-registered" aria-hidden="true"></i>
                                    </sup>
                                    
                                </h4>
                                <p class="align-middle">
                                    No:44 A/47 Kumaragam, 2nd Floor, Abimukeswarar East Street,
                                    <br />
                                    Kumbakonam - 612 001. Thanjavur (Dt)
                                    <br />
                                    Tamilnadu, India.
                                </p>
                            
                                <h1 class="text-light">Phone | E-mail | Website</h1>
                                <p>
                                    <a class="mail-call" href="tel:04352427274">0435 2427274</a>, 
                                    <a class="mail-call" href="tel:+919344303015">+91 9344303015</a>, 
                                    <a class="mail-call" href="tel:+919514687874">+91 9514687874</a> 
                                    <br />
                                    For General Enquiries: 
                                    <a class="mail-call" href="mailto:annaasilicontechnology@gmail.com">annaasilicontechnology@gmail.com</a>
                                    <br /><br>
                                    For Support: 
                                    <a class="mail-call" href="mailto:support@annaasilicontechnology.com">support@annaasilicontechnology.com</a>
                                    <br /><br>
                                    For Sales Enquiries: 
                                    <a class="mail-call" href="mailto:sales@annaasilicontechnology.com">sales@annaasilicontechnology.com</a>
                                    <br />
                                    <a class="mail-call" href="https://www.annaasilicontechnology.com" target="_blank">www.annaasilicontechnology.com</a>
                                    <br />
                                </p>
                                
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-6 text-center contact-field w3-animate-right ">
                    <h1 class="title text-center">Send Us A Message</h1>
                    <h5 class="text-center font-weight-bold">Send Us A Message</h5>
                    <form  ngNativeValidate autocomplete="off" (ngSubmit)="submit1()">
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">Name *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fa fa-user icon" aria-hidden="true"></i>
                                        </span> 
                                    </div>
                                    <input type="name" class="form-control" id="inputEmail4" placeholder="Full Name" name="name" [(ngModel)]="this.contactus.name" required>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">Mobile Number *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fa fa-mobile icon" aria-hidden="true"></i>
                                        </span> 
                                    </div>
                                    <input type="tel" maxlength="10" class="form-control" id="inputEmail4"placeholder="Mobile number" name="mobile" [(ngModel)]="this.contactus.mobileNumber" required>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">E-mail ID *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fa fa-envelope  icon" aria-hidden="true"></i>
                                    </span> 
                                    </div>
                                    <input type="email" class="form-control" id="inputEmail4" placeholder="Email" name="email" [(ngModel)]="this.contactus.mailId" required>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">Enquiry Title  *</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fa fa-file icon" aria-hidden="true"></i>
                                        </span> 
                                    </div>
                                    <input type="text" class="form-control" id="inputEmail4" name="enquiry" placeholder="Enquiry" [(ngModel)]="this.contactus.enquiryType" required>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <label for="inputEmail4">Write a Message *</label>
                                <textarea class="form-control" name="message" placeholder="Message" [(ngModel)]="this.contactus.message" required>
                                </textarea>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                <button id="submit"></button>
                            </div>
                        </div>
                    </form>
                </div>
                    
				
			<!-- </div> -->
            </div>
         </div>
    </div>
    <div class="container-fluid  bounceIn">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d244.81907503372614!2d79.38370628893136!3d10.955425432195545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1734172382702!5m2!1sen!2sin" width="100%" height="300" frameborder="0"  allowfullscreen="" >
        </iframe>
    </div>
</div>
</div>

<!--------------------------------------- MOBILE VIEW ------------------------------------------------->



 