<!------------------------------------------TITLE----------------------------------------------->
<div class="title-box uk-border-rounded p-1 d-none d-sm-block">
  <div class="head-card p-1 inner-title-box">
    <h1 class="title-text text-center font-weight-bold">
      Special Features
    </h1>
    <div class="text-right">
      <h5 class="text-light">
        <span class="sub-menu pr-3" routerLink="/ourproducts">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
          Back
        </span>
        <!-- <span class="sub-menu sub-menu-text font-weight-bold">Custom Software Development</span>&nbsp;/&nbsp; -->
        <!-- <span class="sub-menu" routerLink="/about-us"> Clients</span> -->
      </h5>
    </div>
  </div>
</div>
<!--------------------------------------------------TITLE END-------------------------------------------->



<!----------------------------------------------RETAIL SOFTWARE------------------------------------------->
<div *ngIf="retailFlag==true" class="p-4">

  <div class="accordions">
    <div class="row">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Retail Management</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Master <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Company master</li>
                <li class="list-group-item">Product type</li>
                <li class="list-group-item">Sub type</li>
                <li class="list-group-item">Unit</li>
                <li class="list-group-item">Generic unit</li>
                <li class="list-group-item">Product</li>
              </div>

              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Category</li>
                <li class="list-group-item">Ledger</li>
                <li class="list-group-item">Incentive</li>
                <li class="list-group-item">Client</li>
                <li class="list-group-item">Order</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item2">
            <h2>Transaction <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item2">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Sales</li>
                <li class="list-group-item">Purchase</li>
                <li class="list-group-item">Purchase commission</li>
                <li class="list-group-item">Sales return</li>
                <li class="list-group-item">Purchase return</li>
                <li class="list-group-item">Stock entry</li>
              </div>

              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Stock update</li>
                <!-- <li class="list-group-item">Ledger</li>
                <li class="list-group-item">Incentive</li>
                <li class="list-group-item">Client</li>
                <li class="list-group-item">Order</li> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item3">
            <h2>Accounts <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item3">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Journal entry</li>
                <li class="list-group-item">Receipt entry</li>
                <li class="list-group-item">Payment entry</li>
                <li class="list-group-item">Customer accounts</li>
                <li class="list-group-item">Party accounts</li>
                <li class="list-group-item">Accounts statement</li>
              </div>

              <!-- <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Category</li>
                <li class="list-group-item">Ledger</li>
                <li class="list-group-item">Incentive</li>
                <li class="list-group-item">Client</li>
                <li class="list-group-item">Order</li>
              </div> -->
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item4">
            <h2>Reports<i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item4">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Sales</li>
                <li class="list-group-item">Sales product</li>
                <li class="list-group-item">Purchase</li>
                <li class="list-group-item">Purchase product</li>
                <li class="list-group-item">Sales return</li>
                <li class="list-group-item">Sales return product</li>
                <li class="list-group-item">Purchase return product</li>
                <li class="list-group-item">Quotation</li>
                <li class="list-group-item">Quotation product</li>
                <li class="list-group-item">Stock entry</li>
              </div>

              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Product ledger</li>
                <li class="list-group-item">Daily stock entry</li>
                <li class="list-group-item">Product list</li>
                <li class="list-group-item">Collection report</li>
                <li class="list-group-item">Payment report</li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Admin <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Company details</li>
                <li class="list-group-item">Rate changes</li>
                <li class="list-group-item">Profit table</li>
                <li class="list-group-item">Role creation</li>
                <li class="list-group-item">User creation</li>
                <li class="list-group-item">User profile</li>
              </div>

              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Settings</li>
                <li class="list-group-item">Backup</li>
                <!-- <li class="list-group-item">Incentive</li>
                <li class="list-group-item">Client</li>
                <li class="list-group-item">Order</li> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!--------------------------------------------END---------------------------------------------------------->

<!-- ------------------------------------------SCHOOL MANAGEMENT SYSTEM------------------------------------>
<div *ngIf="schoolFlag==true" class="p-4">

  <div class="accordions">
    <div class="row">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Student & Staff Management</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item">
            <h2>Student Register</h2>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item">
            <h2>Quick Admission</h2>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Master <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Department</li>
                <li class="list-group-item">Staff</li>
                <li class="list-group-item">Class</li>
                <li class="list-group-item">Subject</li>
                <li class="list-group-item">Section</li>
                <li class="list-group-item">Hours</li>
                <li class="list-group-item">Holiday</li>
                <li class="list-group-item">Community</li>
                <li class="list-group-item">Caste</li>
                <li class="list-group-item">Exam</li>
              </div>

              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Exam Schedule</li>
                <li class="list-group-item">Notes</li>
                <li class="list-group-item">Area</li>
                <li class="list-group-item">Term</li>
                <li class="list-group-item">Standard Term</li>
                <li class="list-group-item">Receipt Head </li>
                <li class="list-group-item">Discount Type</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item2">
            <h2>SMS <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item2">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">SMS Creation</li>
                <li class="list-group-item">SMS Master</li>
                <li class="list-group-item">SMS Template Master</li>
                <li class="list-group-item">SMS History </li>
                <li class="list-group-item">SMS Content Settings</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item3">
            <h2>Send SMS at the time of <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item3">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Absent List</li>
                <li class="list-group-item">Fee Allocation </li>
                <li class="list-group-item">Result Posting</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item4">
            <h2>Promotion <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item4">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Result Posting</li>
                <li class="list-group-item">Section Allocation </li>
                <li class="list-group-item">Result Posting</li>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />
      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Fees <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Discount Fixing</li>
                <li class="list-group-item">Fee Allocation </li>
                <li class="list-group-item">Fee Collection</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item6">
            <h2>Bus Fee <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item6">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Fee Allocation</li>
                <li class="list-group-item">Refund </li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item7">
            <h2>Reports<i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item7">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Arrears</li>
                <li class="list-group-item">Non Arrears</li>
                <li class="list-group-item">Standard Term wise</li>
                <li class="list-group-item">Fee Collection details</li>
                <li class="list-group-item">Day wise Refund </li>
                <li class="list-group-item">Bus Students Report</li>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item8">
            <h2>Accounts <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item8">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Ledger Master</li>
                <li class="list-group-item">Ledger Statement</li>
                <li class="list-group-item">Journal Entry</li>
                <li class="list-group-item">payment Entry</li>
                <li class="list-group-item">Receipt Entry</li>
                <li class="list-group-item">Journal List</li>
              </div>

              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Payment List </li>
                <li class="list-group-item">receipt List</li>
                <!-- <li class="list-group-item">Incentive</li>
                <li class="list-group-item">Client</li>
                <li class="list-group-item">Order</li> -->
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item9">
            <h2>Accounts reports <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item9">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">cash Book </li>
                <li class="list-group-item">Multi Account Report </li>
                <!-- <li class="list-group-item">Result Posting</li> -->
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item10">
            <h2>Student Report <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item10">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Admission</li>
                <li class="list-group-item">Individual Admission Details</li>
                <li class="list-group-item">Class wise </li>
                <li class="list-group-item">Class wise </li>
                <li class="list-group-item">Gender wise </li>
                <li class="list-group-item">Community wise </li>
                <li class="list-group-item">Physically disabled</li>
                <li class="list-group-item">Strength wise</li>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />

      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item11">
            <h2>Exam <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item11">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Exam Mark entry</li>
                <li class="list-group-item">Exam Mark Sheet </li>
                <li class="list-group-item">Exam Report </li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item12">
            <h2>Transfer Certificate <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item12">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">TC generation</li>
                <li class="list-group-item">TC reprint </li>
                <li class="list-group-item">TC Report </li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item13">
            <h2>Certificates <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item13">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item font-weight-bolder">Generation</li>
                <li class="list-group-item">Attendance</li>
                <li class="list-group-item">Bonafide</li>
                <li class="list-group-item">Conduct</li>
                <li class="list-group-item">Course </li>
                <li class="list-group-item">Genuine</li>
              </div>

              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item font-weight-bolder">Reprint </li>
                <li class="list-group-item">Attendance</li>
                <li class="list-group-item">Bonafide</li>
                <li class="list-group-item">Conduct</li>
                <li class="list-group-item">Course </li>
                <li class="list-group-item">Genuine</li>
              </div>
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item font-weight-bolder">Report </li>
                <li class="list-group-item">Attendance</li>
                <li class="list-group-item">Bonafide</li>
                <li class="list-group-item">Conduct</li>
                <li class="list-group-item">Course </li>
                <li class="list-group-item">Genuine</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item14">
            <h2>Attendance <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item14">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Attendance entry</li>
                <li class="list-group-item">Day wise Report </li>
                <!-- <li class="list-group-item">TC Report  </li> -->
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item15">
            <h2>Admin <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item15">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Role master</li>
                <li class="list-group-item">User Creation </li>
                <li class="list-group-item">Change password </li>
                <li class="list-group-item">Account Master </li>
                <li class="list-group-item">Backup </li>
                <li class="list-group-item">SMS Settings </li>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
<!------------------------------------------------END----------------------------------------------------->

<!----------------------------------------------ANNAA ANTIBIOTIC------------------------------------------>
<div *ngIf="antibioticFlag==true" class="p-4">

  <div class="accordions">
    <div class="row">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Healthcare Management System</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Register <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12">
                <p>Patient Registration with Photo Uploads.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item2">
            <h2>Appointment <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item2">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12">
                <p>Doctor Appointment for each Patients.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item3">
            <h2>Vital Information <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item3">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12">
                <p>Viewing vital Info for both User Selected Patients and Waiting List Patients.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />
      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item4">
            <h2>Prescription<i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item4">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12">
                <p>Prescription based on both Registered and on Time (Dynamic) Registered Patients.</p>
                <p>Get Older Prescription Details for user selected Patient and also can viewing visiting history for
                  that Patient .</p>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Masters <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Patient Master (Registered Patients List).</li>
                <li class="list-group-item">Maintaining Doctors List with Photos and also can set the Consultation Fees.
                </li>
                <li class="list-group-item">Test Master</li>
                <li class="list-group-item">Receipt Head and Content Master</li>
                <li class="list-group-item">Maintaining Staff</li>
                <li class="list-group-item">Drug Types </li>
                <li class="list-group-item">Complaints </li>
                <li class="list-group-item">Investigation Masters </li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item6">
            <h2>Billing <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item6">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12">
                <p>Both In and Out Patients (with and without GST)</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />

      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item7">
            <h2>Discharge Summary <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item7">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Creating Discharge Summary (both Registered and on Time Registered
                  Patients).</li>
                <li class="list-group-item">Maintaining Discharge Summary List.</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item8">
            <h2>Reports with PDF <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item8">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Date wise Patient Report</li>
                <li class="list-group-item">Doctor wise Report</li>
                <li class="list-group-item">Disease wise and Drug wise Reports</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item9">
            <h2>Admin <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item9">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">User Creation</li>
                <li class="list-group-item">User Rights Control [Role] </li>
                <li class="list-group-item">Profile </li>
                <li class="list-group-item">DB Backup </li>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<!---------------------------------------------END--------------------------------------------------------->

<!--------------------------------ELLORA PHARMACY---------------------------------------------------------->
<div *ngIf="elloraFlag==true" class="p-4">

  <div class="accordions">
    <div class="row">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Pharmacy Management System</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Master <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Area Master</li>
                <li class="list-group-item">Location Master</li>
                <li class="list-group-item">Manager Master</li>
                <li class="list-group-item">Employee Master</li>
                <li class="list-group-item">Doctor Master</li>
                <li class="list-group-item">Gift Master</li>
                <li class="list-group-item">Sample Master</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item2">
            <h2>Entry <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item2">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Gift Entry</li>
                <li class="list-group-item">Sample Entry</li>
                <!-- <li class="list-group-item">Purchase commission</li>
                <li class="list-group-item">Sales return</li>
                <li class="list-group-item">Purchase return</li>
                <li class="list-group-item">Stock entry</li> -->
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item3">
            <h2>Transaction <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item3">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Gift Transaction</li>
                <li class="list-group-item">Sample Transaction</li>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item4">
            <h2>Reports<i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item4">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Area Assigning Report</li>
                <li class="list-group-item">Chemist Report</li>
                <li class="list-group-item">Purchase</li>
                <li class="list-group-item">Date Wise Report</li>
                <li class="list-group-item">Doctor Database Report</li>
                <li class="list-group-item">Employee Database Report</li>
                <li class="list-group-item">Doctor Visit Report</li>
                <li class="list-group-item">Entry Report</li>
                <li class="list-group-item">Gift Wise Report</li>
              </div>

              <div class="col-md-6 col-sm-12 col-12">
                <li class="list-group-item">Gift Transaction Report</li>
                <li class="list-group-item">Leave wise Report</li>
                <li class="list-group-item">Manager Report</li>
                <li class="list-group-item">Sample Wise report</li>
                <li class="list-group-item">Collection report</li>
                <li class="list-group-item">Sample Transaction Report</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Monitor <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Activity Monitor</li>
                <li class="list-group-item">Gift Monitor</li>
                <li class="list-group-item">Sample Monitor</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item6">
            <h2>Administration <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item6">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Area Assigning </li>
                <li class="list-group-item">Stocks</li>
                <li class="list-group-item">Settings</li>
                <li class="list-group-item">Role Creation</li>
                <li class="list-group-item">User Creation</li>
                <li class="list-group-item">User Profile</li>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />

      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item7">
            <h2>User Activities <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item7">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">New Activity </li>
                <li class="list-group-item">Recent Activity</li>
                <li class="list-group-item">Chemist</li>
                <li class="list-group-item">Gift Monitor</li>
                <li class="list-group-item">Sample Monitor</li>
                <li class="list-group-item">Gift Report</li>
                <li class="list-group-item">Sample Report</li>
                <li class="list-group-item">User Details</li>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
<!-----------------------------------------------END------------------------------------------------------->

<!----------------------------------------------Library MANAGEMENT SYSTEM------------------------------------>
<div *ngIf="lodgeFlag==true" class="p-4">

  <div class="accordions">
    <div class="row justify-content-center">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Library Management System</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Master <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Book group</li>
                <li class="list-group-item">Author</li>
                <li class="list-group-item">Publisher</li>
                <li class="list-group-item">Book</li>
                <li class="list-group-item">Category</li>
                <li class="list-group-item">customer master</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item2">
            <h2>Transaction <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item2">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Book issue</li>
                <li class="list-group-item">Book return</li>
                <li class="list-group-item">Book out</li>
                <li class="list-group-item">Book entry</li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item3">
            <h2>Report <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item3">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Book master</li>
                <li class="list-group-item">Customer master</li>
                <li class="list-group-item">Book issue</li>
                <li class="list-group-item">Book return</li>
                <li class="list-group-item">Book entry</li>
                <li class="list-group-item">Accounts statement</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Admin <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Role creation</li>
                <li class="list-group-item">User creation</li>
                <li class="list-group-item">User profile</li>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />
    </div>
  </div>

</div>
<!---------------------------------------------END----------------------------------------------------------->

<!----------------------------------------------TICKET BOOKING SOFTWARE ------------------------------------->
<div *ngIf="ticketFlag==true" class="p-4">

  <div class="accordions">
    <div class="row justify-content-center">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Ticket Booking Software</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Ticket Booking</h2>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Ticket Verification (QR Scanner)</h2>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Master <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Devotee</li>
                <li class="list-group-item">Booking</li>
                <li class="list-group-item">Ticket</li>
                <li class="list-group-item">Location Master</li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item4">
            <h2>Reports<i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item4">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Day wise</li>
                <li class="list-group-item">Status</li>
                <li class="list-group-item">Abstract</li>
                <li class="list-group-item">Location wise Sales </li>
                <li class="list-group-item">Location wise Sales Details</li>
                <li class="list-group-item">Location Abstract</li>
                <li class="list-group-item">Overall sales abstract </li>
                <li class="list-group-item">Consolidated location </li>
                <li class="list-group-item">Quotation product</li>
                <li class="list-group-item">Month wise Report</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Admin <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Role creation</li>
                <li class="list-group-item">User creation</li>
                <li class="list-group-item">User profile</li>
                <li class="list-group-item">Settings</li>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />
    </div>
  </div>

</div>

<!---------------------------------------------END----------------------------------------------------------->

<!---------------------------------------------ENGINEERING SOFTWARE SYSTEM ---------------------------------->
<div *ngIf="engineeringFlag==true" class="p-4">

  <div class="accordions">
    <div class="row justify-content-center">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Engineering Section Software</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Master <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Receipt Type</li>
                <li class="list-group-item">Tender Bidder</li>
                <li class="list-group-item">Section</li>
                <li class="list-group-item">File Creation</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item2">
            <h2>Entry <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item2">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Estimation</li>
                <li class="list-group-item">Quotation</li>
                <li class="list-group-item">Tender</li>
                <li class="list-group-item">Receipt</li>
                <li class="list-group-item">Tender Action </li>
                <li class="list-group-item">Tender Bidder Document</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item3">
            <h2>Work <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item3">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Work Order</li>
                <li class="list-group-item">Agreement</li>
                <li class="list-group-item">Work Progress</li>
                <li class="list-group-item">Payment </li>
                <li class="list-group-item">Work Completion</li>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item4">
            <h2>Action Process <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item4">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Tender </li>
                <li class="list-group-item">Quotation Action</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Report <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Tender</li>
                <li class="list-group-item">Quotation</li>
                <li class="list-group-item">Progress</li>
                <li class="list-group-item">Payment </li>
                <li class="list-group-item">Bidder Report</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item6">
            <h2>Admin <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item6">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Role Creation</li>
                <li class="list-group-item">User Creation</li>
                <li class="list-group-item">Change Password</li>
                <li class="list-group-item">BackUp </li>
                <li class="list-group-item">PDF Settings</li>
              </div>
            </div>
          </div>
        </div>


      </div>
      <br />
    </div>
  </div>

</div>

<!---------------------------------------------END----------------------------------------------------------->

<!---------------------------------------------Inventory  SOFTWARE SYSTEM ---------------------------------->
<div *ngIf="inventoryFlag==true" class="p-4">

  <div class="accordions">
    <div class="row justify-content-center">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Inventory Software</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Master <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Product Type</li>
                <li class="list-group-item">Unit</li>
                <li class="list-group-item">Product </li>
                <li class="list-group-item">Plan</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item2">
            <h2>Entry <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item2">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Stock</li>
                <li class="list-group-item">Plan Bulk </li>
                <li class="list-group-item">Product Entry</li>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />
      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Report <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Plan Bulk</li>
                <li class="list-group-item">Stock</li>
                <li class="list-group-item">Date Stock</li>
                <li class="list-group-item">Thittam Detail </li>
                <li class="list-group-item">Date Stock Detail Report</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item6">
            <h2>Admin <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item6">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Role Creation</li>
                <li class="list-group-item">User Creation</li>
                <li class="list-group-item">Change Password</li>
                <li class="list-group-item">BackUp </li>
              </div>
            </div>
          </div>
        </div>


      </div>
      <br />
    </div>
  </div>

</div>

<!---------------------------------------------END----------------------------------------------------------->

<!---------------------------------------------TEMPLE  SOFTWARE SYSTEM ---------------------------------->
<div *ngIf="templeswFlag==true" class="p-4">

  <div class="accordions">
    <div class="row justify-content-center">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder">Temple Software</h2>
      </div>
      <div class="col-md-4 col-sm-6 col-12">
        <div class="accordion-item">
          <div class="accordion-title" data-tab="item1">
            <h2>Ticketing <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item1">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Handheld Device Ticket</li>
                <li class="list-group-item">Abisegam &nbsp; & &nbsp; ThangaKavasam Booking </li>
                <li class="list-group-item">kanikkai Tickets</li>
                <li class="list-group-item">Wedding Tickets</li>
                <li class="list-group-item">All Reports relevant to Ticktes</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item2">
            <h2>Accounts <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item2">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12 text-center">
                <li class="list-group-item">MR Booking</li>
                <li class="list-group-item">Payment Voucher Entry </li>
                <li class="list-group-item">Bank Transaction Entry</li>
                <li class="list-group-item">Bank Reconciliation</li>
              </div>
              <div class="col-md-6 col-sm-12 col-12 text-center">
                <li class="list-group-item">Cash Book</li>
                <li class="list-group-item">Ledger Report </li>
                <li class="list-group-item">Receipt & Expense Report</li>
                <li class="list-group-item">MO, Cheque, DD register</li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item3">
            <h2>Invesment <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item3">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">FD Register</li>
                <li class="list-group-item">FD Renwal Details</li>
                <li class="list-group-item">FD Re investment</li>
                <li class="list-group-item">FD Withdrwal </li>
                <li class="list-group-item">Bank & Head Wise Report</li>
              </div>
            </div>
          </div>
        </div>

      </div>

      <br />

      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item4">
            <h2>Deposit Register </h2>
          </div>
          <!-- <div class="accordion-content" id="item4">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Role Creation</li>
                <li class="list-group-item">User Creation</li>
                <li class="list-group-item">Change Password</li>
                <li class="list-group-item">BackUp </li>
              </div>
            </div>
          </div> -->
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item5">
            <h2>Advance Register </h2>
          </div>
          <!-- <div class="accordion-content" id="item5">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Role Creation</li>
                <li class="list-group-item">User Creation</li>
                <li class="list-group-item">Change Password</li>
                <li class="list-group-item">BackUp </li>
              </div>
            </div>
          </div> -->
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item6">
            <h2>DCB Register <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item6">
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12 text-center">
                <li class="list-group-item">Tennant Register</li>
                <li class="list-group-item">Demand and Old Pending Entry</li>
                <li class="list-group-item">DCB Collection</li>
              </div>
              <div class="col-md-6 col-sm-12 col-12 text-center">
                <li class="list-group-item">Degree Court </li>
                <li class="list-group-item">DCB Remission</li>
                <li class="list-group-item">DCB Report </li>
              </div>
            </div>
          </div>
        </div>

      </div>

      <br>

      <div class="col-md-4 col-sm-6 col-12">

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item7">
            <h2>Stock Register <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item7">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Kanikkai Register</li>
                <li class="list-group-item">Nithyapadi Register</li>
                <li class="list-group-item">Stock Abstract </li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item8">
            <h2>Share <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item8">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Ticket Shareholder Master</li>
                <li class="list-group-item">Ticket Share Entry</li>
                <li class="list-group-item">Ticket Share Report </li>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <div class="accordion-title" data-tab="item9">
            <h2>Kattalai Pooja Subscription <i class="fa fa-chevron-down"></i></h2>
          </div>
          <div class="accordion-content" id="item9">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12 text-center">
                <li class="list-group-item">Kattalai Pooja Master Entry</li>
                <li class="list-group-item">Kattalai Pooja Reports </li>
              </div>
            </div>
          </div>
        </div>


      </div>

      <br />
    </div>
  </div>

  <div class="container-fluid">
    <div class="row mt-4 mb-3">
      <div class="col-12 text-center mb-2">
        <h2 class="font-weight-bolder text-capitalize">
          annadhanam software
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p class="text-center h5 text-dark">
          Apart from the above software we also have Annadhanam Software separtely.
        </p>
      </div>
    </div>

  </div>

</div>

<!---------------------------------------------END----------------------------------------------------------->