import { Component, OnInit } from '@angular/core';


declare var bootbox: any;
declare var $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    

    // ANIMATION

    $(document).ready(function($) {
  
      // Function which adds the 'animated' class to any '.animatable' in view
      var doAnimations = function() {
        
        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
            $animatables = $('.animatable');
        
        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }
        
        // Check all animatables and animate them if necessary
        $animatables.each(function(i) {
           var $animatable = $(this);
          if (($animatable.offset().top + $animatable.height() -1) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
          }
        });
    
      };
      
      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');
    
    });

    //

    $(document).ready(function() {
      $("#myModal").modal('show');
    });
    
    setTimeout(function() {
      $('#myModal').modal('hide');
    }, 3000);

    $(".slide img").on("click" , function(){
      $(this).toggleClass('zoomed');
      $(".overlay").toggleClass('active');
      })

    $(document).ready(function(){ 
      $(window).scroll(function(){ 
          if ($(this).scrollTop() > 100) { 
              $('#scroll').fadeIn(); 
          } else { 
              $('#scroll').fadeOut(); 
          } 
      }); 
      $('#scroll').click(function(){ 
          $("html, body").animate({ scrollTop: 0 }, 600); 
          return false; 
      }); 
    });

  }

  

  prev(){
    document.getElementById('slider-container').scrollLeft -= 270;
  }
  
  next()
  {
    document.getElementById('slider-container').scrollLeft += 270;
  }
  
}
